import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { sectors } from './sectors';
import { disciplines } from './disciplines';
import './LoginRegisterPage.css';

Modal.setAppElement('#root');

const LoginRegisterPage = ({ isOpen, closeModal, setUser }) => {
    const [isRegistering, setIsRegistering] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        sector: '',
        discipline: '',
        country: '',
        organization: '' // Add organization to form data
    });
    const [countries, setCountries] = useState([]);
    const [organizations, setOrganizations] = useState([]); // Store organizations list for dropdown
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch list of countries
        const fetchCountries = async () => {
            try {
                const response = await fetch('https://restcountries.com/v3.1/all');
                const data = await response.json();
                const countryNames = data.map(country => country.name.common).sort();
                setCountries(countryNames);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        // Fetch list of organizations
        const fetchOrganizations = async () => {
            try {
                const response = await axios.get('/api/organizations');
                setOrganizations(response.data); // Assume response is an array of organization names
            } catch (error) {
                console.error('Error fetching organizations:', error);
            }
        };

        fetchCountries();
        fetchOrganizations();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const { username, password } = formData;
        try {
            const response = await axios.post('/api/login', { username, password }, {
                headers: { 'Content-Type': 'application/json' }
            });

            const { user } = response.data;
            setUser(user);
            localStorage.setItem('user', JSON.stringify(user));
            setError('');
            closeModal();
        } catch (error) {
            console.error('Error logging in:', error);
            setError('Invalid username or password. Please try again.');
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/register', formData);
            const { user } = response.data;
            setUser(user);
            localStorage.setItem('user', JSON.stringify(user));
            setError('');
            closeModal();
        } catch (error) {
            setError('Error registering. Please try again.');
            console.error('Error registering:', error);
        }
    };

    useEffect(() => {
        if (isOpen) {
            window.scrollTo(0, 0);
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Login/Register Modal"
            className="login-register-modal-content"
            overlayClassName="login-register-modal-overlay"
        >
            <div className="macos-window-controls">
                <div className="macos-button close" onClick={() => closeModal()}></div>
            </div>
            <div className="login-register-modal-header">
                <h2 className="login-register-title">{isRegistering ? 'Register' : 'Login'}</h2>
            </div>
            <div className="login-register-modal-body">
                {error && <div className="error-message">{error}</div>}
                {isRegistering ? (
                    <form onSubmit={handleRegister} className="login-register-form">
                        <div>
                            <label htmlFor="firstName" className="login-register-label">First Name:</label>
                            <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required className="login-register-input" />
                        </div>
                        <div>
                            <label htmlFor="lastName" className="login-register-label">Last Name:</label>
                            <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required className="login-register-input" />
                        </div>
                        <div>
                            <label htmlFor="email" className="login-register-label">Email:</label>
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required className="login-register-input" />
                        </div>
                        <div>
                            <label htmlFor="username" className="login-register-label">Username:</label>
                            <input type="text" id="username" name="username" value={formData.username} onChange={handleChange} required className="login-register-input" />
                        </div>
                        <div>
                            <label htmlFor="password" className="login-register-label">Password:</label>
                            <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} required className="login-register-input" />
                        </div>
                        <div>
                            <label htmlFor="sector" className="login-register-label">Sector:</label>
                            <select id="sector" name="sector" value={formData.sector} onChange={handleChange} required className="login-register-select">
                                <option value="">Select a sector</option>
                                {sectors.map(sector => (
                                    <option key={sector} value={sector}>{sector}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="discipline" className="login-register-label">Discipline:</label>
                            <select id="discipline" name="discipline" value={formData.discipline} onChange={handleChange} required className="login-register-select">
                                <option value="">Select a discipline</option>
                                {disciplines.map(discipline => (
                                    <option key={discipline} value={discipline}>{discipline}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="country" className="login-register-label">Country:</label>
                            <select id="country" name="country" value={formData.country} onChange={handleChange} required className="login-register-select">
                                <option value="">Select a country</option>
                                {countries.map(country => (
                                    <option key={country} value={country}>{country}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="organization" className="login-register-label">Organization:</label>
                            <select id="organization" name="organization" value={formData.organization} onChange={handleChange} required className="login-register-select">
                                <option value="">Select an organization</option>
                                {organizations.map(org => (
                                    <option key={org.id} value={org.name}>{org.name}</option>
                                ))}
                            </select>
                        </div>
                        <button type="submit" className="login-register-button">Register</button>
                        <button type="button" onClick={() => setIsRegistering(false)} className="login-register-button">Go to Login</button>
                    </form>
                ) : (
                    <form onSubmit={handleLogin} className="login-register-form">
                        <div>
                            <label htmlFor="username" className="login-register-label">Username:</label>
                            <input type="text" id="username" name="username" value={formData.username} onChange={handleChange} required className="login-register-input" />
                        </div>
                        <div>
                            <label htmlFor="password" className="login-register-label">Password:</label>
                            <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} required className="login-register-input" />
                        </div>
                        <button type="submit" className="login-register-button">Login</button>
                        <button type="button" onClick={() => setIsRegistering(true)} className="login-register-button">Go to Register</button>
                    </form>
                )}
            </div>
        </Modal>
    );
};

export default LoginRegisterPage;
