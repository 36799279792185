import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import Navbar from './components/Navbar';
import LoginRegisterPage from './pages/LoginRegisterPage';
import Footer from './pages/common/Footer';
import HomePage from './pages/common/HomePage';
import Disclaimer from './pages/common/Disclaimer';

// Organization-specific components
import ElwandleOperationsDashboard from './pages/elwandle/ElwandleDashboard';
import ElwandleOnlineDataEntry from './pages/elwandle/OnlineDataEntry';
import ElwandleOfflineForms from './pages/elwandle/OfflineForms';
import EFTEONOperationsDashboard from './pages/loggernet/OperationsDashboard';
import EFTEONOnlineDataEntry from './pages/loggernet/OnlineDataEntry';
import EFTEONOfflineForms from './pages/loggernet/OfflineForms';
import AdminPanel from './pages/AdminPanel';

Modal.setAppElement('#root');

function App() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [selectedNode, setSelectedNode] = useState('');

    const openLoginModal = () => setIsLoginModalOpen(true);
    const closeLoginModal = () => setIsLoginModalOpen(false);

    const handleLogout = () => {
        setUser(null);
        localStorage.removeItem('user');
        setSelectedNode('');
    };

    return (
        <Router>
            <Navbar
                openLoginModal={openLoginModal}
                user={user}
                onLogout={handleLogout}
                selectedNode={selectedNode}
                setSelectedNode={setSelectedNode}
            />
            <div className="App">
                <Routes>
                    {/* Home Page */}
                    <Route
                        path="/"
                        element={<HomePage
                            user={user}
                            setSelectedNode={setSelectedNode}
                            selectedNode={selectedNode}
                            openLoginModal={openLoginModal}
                        />}
                    />

                    {/* Dynamic routes for each node's home page */}
                    <Route
                        path="/:node/home"
                        element={<HomePage
                            user={user}
                            setSelectedNode={setSelectedNode}
                            selectedNode={selectedNode}
                            openLoginModal={openLoginModal}
                        />}
                    />

                    {/* Admin Access */}
                    {user && user.role === 'Admin' && (
                        <>
                            <Route path="/admin-panel" element={<AdminPanel currentUser={user} />} />
                            <Route path="/operations-dashboard" element={<ElwandleOperationsDashboard />} />
                            <Route path="/online-data-entry" element={<ElwandleOnlineDataEntry />} />
                            <Route path="/offline-forms" element={<ElwandleOfflineForms />} />
                        </>
                    )}

                    {/* Technician Access for Elwandle */}
                    {user && (user.role === 'Technician' || user.role === 'Admin') && (
                        <>
                            <Route path="/elwandle/operations-dashboard" element={<ElwandleOperationsDashboard user={user} />} />
                            <Route path="/elwandle/online-data-entry" element={<ElwandleOnlineDataEntry user={user} />} />
                            <Route path="/elwandle/offline-forms" element={<ElwandleOfflineForms user={user} />} />
                        </>
                    )}
                    {user && user.role === 'Technician' && user.organization === 'EFTEON' && (
                        <>
                            <Route path="/operations-dashboard" element={<EFTEONOperationsDashboard />} />
                            <Route path="/online-data-entry" element={<EFTEONOnlineDataEntry />} />
                            <Route path="/offline-forms" element={<EFTEONOfflineForms />} />
                        </>
                    )}
                </Routes>

                <LoginRegisterPage
                    isOpen={isLoginModalOpen}
                    closeModal={closeLoginModal}
                    setUser={(user) => {
                        setUser(user);
                        localStorage.setItem('user', JSON.stringify(user));
                    }}
                />
            </div>
            <Footer />
        </Router>
    );
}

export default App;
