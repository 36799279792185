import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ElwandleDashboard.css';
import { FaHome, FaInfoCircle } from 'react-icons/fa'; // Import home and info icons
import AssetManagementModal from './AssetManagementModal';
import CategoryManagementModal from './CategoryManagementModal';
import InfoModal from './InfoModal';

// Function to open IndexedDB
async function openIndexedDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('AssetDB', 1);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('assets')) {
                db.createObjectStore('assets', { keyPath: 'id', autoIncrement: true });
            }
        };

        request.onsuccess = (event) => resolve(event.target.result);
        request.onerror = (event) => reject(event.target.error);
    });
}

// Function to check the number of pending uploads in IndexedDB
async function checkPendingUploads() {
    const db = await openIndexedDB();
    const tx = db.transaction('assets', 'readonly');
    const store = tx.objectStore('assets');

    return new Promise((resolve, reject) => {
        const countRequest = store.count();

        countRequest.onsuccess = () => resolve(countRequest.result);
        countRequest.onerror = () => reject(countRequest.error);
    });
}

function ElwandleDashboard() {
    const { state } = useLocation();
    const { user, selectedNode } = state || {};
    const navigate = useNavigate();
    const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [infoContent, setInfoContent] = useState(''); // State to store unique content for each modal
    const [pendingUploads, setPendingUploads] = useState(0);

    const updatePendingUploads = async () => {
        const count = await checkPendingUploads();
        setPendingUploads(count);
    };

    useEffect(() => {
        updatePendingUploads();
    }, []);

    const handleBackToHome = () => {
        navigate('/');
    };

    const handleOpenAssetModal = () => {
        setIsAssetModalOpen(true);
    };

    const handleCloseAssetModal = async () => {
        setIsAssetModalOpen(false);
        await updatePendingUploads();
    };

    const handleOpenCategoryModal = () => {
        setIsCategoryModalOpen(true);
    };

    const handleCloseCategoryModal = () => {
        setIsCategoryModalOpen(false);
    };

    const handleOpenInfoModal = (content) => {
        setInfoContent(content); // Set unique content
        setIsInfoModalOpen(true);
    };

    const handleCloseInfoModal = () => {
        setIsInfoModalOpen(false);
        setInfoContent(''); // Clear content after closing
    };

    return (
        <div className="operations-dashboard">
            {/* Home Button as Icon */}
            <div className="home-button" onClick={handleBackToHome}>
                <FaHome />
            </div>

            {/* Pending Uploads Badge */}
            {pendingUploads > 0 && (
                <span className="badge">{pendingUploads} Pending</span>
            )}

            <h1>{selectedNode} Asset Categories</h1>
            {user && <p>Welcome, {user.username}. You are viewing the asset categories for {selectedNode}.</p>}

            <div className="dashboard-content">
                <div className="dashboard-section" onClick={handleOpenCategoryModal}>
                    <FaInfoCircle
                        className="info-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpenInfoModal('Information about Asset Category Management');
                        }}
                    />
                    <h3>Asset Category Management</h3>
                    <p>Click here to create and manage asset categories and fields.</p>
                </div>

                <div className="dashboard-section manage-assets-section" onClick={handleOpenAssetModal}>
                    <FaInfoCircle
                        className="info-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpenInfoModal('Information about Asset Field Configuration');
                        }}
                    />
                    <h3>Asset Field Configuration</h3>
                    <p>Click here to configure fields for each asset category under {selectedNode}.</p>
                </div>

                <div className="dashboard-section">
                    <FaInfoCircle
                        className="info-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpenInfoModal('Information about Recent Metrics');
                        }}
                    />
                    <h3>Recent Metrics</h3>
                    <p>Sample data for {selectedNode}. Here you can monitor key metrics.</p>
                </div>

                <div className="dashboard-section">
                    <FaInfoCircle
                        className="info-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpenInfoModal('Information about Reports');
                        }}
                    />
                    <h3>Reports</h3>
                    <p>Access and generate reports for {selectedNode} research activities.</p>
                </div>
            </div>

            {isAssetModalOpen && (
                <AssetManagementModal
                    onClose={handleCloseAssetModal}
                    organization={selectedNode}
                />
            )}

            {isCategoryModalOpen && (
                <CategoryManagementModal
                    onClose={handleCloseCategoryModal}
                    organization={selectedNode}
                />
            )}

            {isInfoModalOpen && (
                <InfoModal onClose={handleCloseInfoModal} content={infoContent} />
            )}
        </div>
    );
}

export default ElwandleDashboard;
