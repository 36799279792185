import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

function HomePage({ user, setSelectedNode, openLoginModal, selectedNode }) {
    const [availableNodes, setAvailableNodes] = useState([]);
    const navigate = useNavigate();

    const nodes = [
        { name: 'AridLands', icon: '/images/arid.png', description: 'Desert and arid regions research' },
        { name: 'Egagasini', icon: '/images/egagasini.png', description: 'Oceanography and marine ecosystems' },
        { name: 'Elwandle', icon: '/images/elwandle.png', description: 'Marine and coastal research' },
        { name: 'Fynbos', icon: '/images/fynbos.png', description: 'Fynbos biome studies' },
        { name: 'GrassLands', icon: '/images/grasslands.png', description: 'Grasslands and savanna research' },
        { name: 'Ndlovu', icon: '/images/ndlovu.png', description: 'Biodiversity and conservation' },
        { name: 'EFTEON', icon: '/images/EFTEON.png', description: 'Environmental monitoring and observation' },
        { name: 'SAPRI', icon: '/images/sapri.png', description: 'Polar research initiatives' },
        { name: 'SMCRI', icon: '/images/smcri.png', description: 'Marine and coastal research infrastructure' },
        { name: 'Ulwazi', icon: '/images/ulwazi.png', description: 'Data and innovation' },
    ];

    useEffect(() => {
        if (user) {
            const userNodes = user.role === 'Admin'
                ? nodes
                : nodes.filter((node) => user.organizations.includes(node.name));
            setAvailableNodes(userNodes);
        } else {
            setAvailableNodes(nodes);
        }
    }, [user]);

    // const handleNodeClick = (nodeName) => {
    //     if (user) {
    //         setSelectedNode(nodeName);
    //         navigate(`/${nodeName.toLowerCase()}/operations-dashboard`, {
    //             state: { user, selectedNode: nodeName },
    //         });
    //     } else {
    //         openLoginModal();
    //     }
    // };

    const handleNodeClick = (nodeName) => {
        if (user) {
            setSelectedNode(nodeName);
            navigate(`/elwandle/operations-dashboard`, {
                state: { user, selectedNode: nodeName },
            });
        } else {
            openLoginModal();
        }
    };

    return (
        <div className="home-page">
            <h1 className="home-title">Welcome to the SAEON OIMS</h1>
            <p className="home-subtitle">Select a research hub to continue</p>
            <div className="nodes-grid">
                {availableNodes.map((node) => (
                    <div
                        key={node.name}
                        className={`node-card ${selectedNode === node.name ? 'active-node' : ''}`}
                        onClick={() => handleNodeClick(node.name)}
                    >
                        <img src={node.icon} alt={`${node.name} icon`} className="node-icon" />
                        <div className="node-info">
                            <h3 className="node-name">{node.name}</h3>
                            <p className="node-description">{node.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HomePage;
