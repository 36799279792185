import React, { useState, useEffect } from 'react';
import './AssetManagementModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faCheckCircle, faExclamationCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

function AssetManagementModal({ onClose, organization }) {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [fields, setFields] = useState([]);
    const [updateFields, setUpdateFields] = useState([]);
    const [maintenanceFields, setMaintenanceFields] = useState([]);
    const [statusFields, setStatusFields] = useState([]);
    const [newField, setNewField] = useState({ field_name: '', field_type: 'text', is_required: true });
    const [editingField, setEditingField] = useState(null);
    const [message, setMessage] = useState(null);
    const [activeTab, setActiveTab] = useState('main');

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => setMessage(null), 1500);
            return () => clearTimeout(timer);
        }
    }, [message]);

    const fetchCategories = async () => {
        try {
            const response = await fetch(`/api/assets/categories?organization=${organization}`);
            if (!response.ok) throw new Error('Failed to fetch categories');
            const data = await response.json();
            setCategories(data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchFields = async (categoryId, fieldType) => {
        try {
            let endpoint = '';
            if (fieldType === 'main') endpoint = `/api/assets/fields?category_id=${categoryId}`;
            else if (fieldType === 'update') endpoint = `/api/assets/update_fields?category_id=${categoryId}`;
            else if (fieldType === 'maintenance') endpoint = `/api/assets/maintenance_fields?category_id=${categoryId}`;
            else if (fieldType === 'status') endpoint = `/api/assets/status_fields?category_id=${categoryId}`;

            const response = await fetch(endpoint);
            if (!response.ok) throw new Error(`Failed to fetch ${fieldType} fields`);
            const data = await response.json();

            if (fieldType === 'main') setFields(data);
            else if (fieldType === 'update') setUpdateFields(data);
            else if (fieldType === 'maintenance') setMaintenanceFields(data);
            else if (fieldType === 'status') setStatusFields(data);
        } catch (error) {
            console.error(`Error fetching ${fieldType} fields:`, error);
        }
    };

    const handleCategoryChange = (categoryId) => {
        setSelectedCategory(categoryId);
        fetchFields(categoryId, 'main');
        fetchFields(categoryId, 'update');
        fetchFields(categoryId, 'maintenance');
        fetchFields(categoryId, 'status');
    };

    const handleTabChange = (type) => {
        setActiveTab(type);
    };

    const handleFieldChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewField((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleAddField = async (fieldType) => {
        const existingFields =
            fieldType === 'main' ? fields :
                fieldType === 'update' ? updateFields :
                    fieldType === 'maintenance' ? maintenanceFields :
                        statusFields;

        const duplicateField = existingFields.some(field => field.field_name === newField.field_name);

        if (duplicateField) {
            setMessage({ type: 'error', text: `Field "${newField.field_name}" already exists in ${fieldType} fields.` });
            return;
        }

        let endpoint = '';
        if (fieldType === 'main') endpoint = `/api/assets/fields`;
        else if (fieldType === 'update') endpoint = `/api/assets/update_fields`;
        else if (fieldType === 'maintenance') endpoint = `/api/assets/maintenance_fields`;
        else if (fieldType === 'status') endpoint = `/api/assets/status_fields`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ category_id: selectedCategory, ...newField }),
            });

            if (!response.ok) throw new Error(`Failed to add ${fieldType} field`);

            setMessage({ type: 'success', text: `${fieldType} field added successfully` });
            fetchFields(selectedCategory, fieldType);
            setNewField({ field_name: '', field_type: 'text', is_required: true });
        } catch (error) {
            console.error(`Error adding ${fieldType} field:`, error);
            setMessage({ type: 'error', text: `Failed to add ${fieldType} field` });
        }
    };

    const handleEditField = (field) => {
        setNewField({ field_name: field.field_name, field_type: field.field_type, is_required: field.is_required });
        setEditingField(field);
    };

    const handleSaveEdit = async (fieldType) => {
        if (!editingField) return;

        try {
            let endpoint = '';
            if (fieldType === 'main') endpoint = `/api/assets/fields/${editingField.id}`;
            else if (fieldType === 'update') endpoint = `/api/assets/update_fields/${editingField.id}`;
            else if (fieldType === 'maintenance') endpoint = `/api/assets/maintenance_fields/${editingField.id}`;
            else if (fieldType === 'status') endpoint = `/api/assets/status_fields/${editingField.id}`;

            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newField),
            });

            if (!response.ok) throw new Error(`Failed to update ${fieldType} field`);

            setMessage({ type: 'success', text: `${fieldType} field updated successfully` });
            fetchFields(selectedCategory, fieldType);
            setNewField({ field_name: '', field_type: 'text', is_required: true });
            setEditingField(null);
        } catch (error) {
            console.error(`Error updating ${fieldType} field:`, error);
            setMessage({ type: 'error', text: `Failed to update ${fieldType} field` });
        }
    };

    const handleDeleteField = async (fieldId, fieldType) => {
        if (!window.confirm(`Are you sure you want to delete this ${fieldType} field?`)) return;

        try {
            let endpoint = '';
            if (fieldType === 'main') endpoint = `/api/assets/fields/${fieldId}`;
            else if (fieldType === 'update') endpoint = `/api/assets/update_fields/${fieldId}`;
            else if (fieldType === 'maintenance') endpoint = `/api/assets/maintenance_fields/${fieldId}`;
            else if (fieldType === 'status') endpoint = `/api/assets/status_fields/${fieldId}`;

            const response = await fetch(endpoint, { method: 'DELETE' });

            if (!response.ok) throw new Error(`Failed to delete ${fieldType} field`);

            setMessage({ type: 'success', text: `${fieldType} field deleted successfully` });
            fetchFields(selectedCategory, fieldType);
        } catch (error) {
            console.error(`Error deleting ${fieldType} field:`, error);
            setMessage({ type: 'error', text: `Failed to delete ${fieldType} field` });
        }
    };

    const renderFields = () => {
        const fieldList =
            activeTab === 'main' ? fields :
                activeTab === 'update' ? updateFields :
                    activeTab === 'maintenance' ? maintenanceFields :
                        statusFields;

        return (
            <div className="field-list">
                {fieldList.map((field) => (
                    <div key={field.id} className="field-item">
                        <span>{field.field_name} ({field.field_type})</span>
                        <div className="field-actions">
                            <button onClick={() => handleEditField(field)} className="button-edit">
                                <FontAwesomeIcon icon={faEdit} /> Edit
                            </button>
                            <button onClick={() => handleDeleteField(field.id, activeTab)} className="button-delete">
                                <FontAwesomeIcon icon={faTrash} /> Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Manage Fields & Updates for {organization}</h2>

                {message && (
                    <div className={`message-popup ${message.type}`}>
                        <FontAwesomeIcon icon={message.type === 'success' ? faCheckCircle : faExclamationCircle} />
                        {message.text}
                    </div>
                )}

                <div className="category-dropdown">
                    <label htmlFor="categorySelect">Select Category:</label>
                    <select
                        id="categorySelect"
                        value={selectedCategory}
                        onChange={(e) => handleCategoryChange(e.target.value)}
                    >
                        <option value="">Choose a Category</option>
                        {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="tab-buttons">
                    {['main', 'update', 'maintenance', 'status'].map((type) => (
                        <button
                            key={type}
                            className={`tab-button ${activeTab === type ? 'active' : ''}`}
                            onClick={() => handleTabChange(type)}
                        >
                            {type.charAt(0).toUpperCase() + type.slice(1)} Fields
                        </button>
                    ))}
                </div>

                <div className="field-form">
                    <h3>{editingField ? 'Edit Field' : 'Add Field'}</h3>
                    <input
                        type="text"
                        name="field_name"
                        value={newField.field_name}
                        onChange={handleFieldChange}
                        placeholder="Field Name"
                    />
                    <select name="field_type" value={newField.field_type} onChange={handleFieldChange}>
                        <option value="text">Text</option>
                        <option value="integer">Integer</option>
                        <option value="numeric">Numeric</option>
                        <option value="date">Date</option>
                        <option value="time">Time</option>
                        <option value="boolean">Boolean</option>
                        <option value="coordinates">Coordinates (e.g., latitude/longitude)</option>
                    </select>
                    <label>
                        <input
                            type="checkbox"
                            name="is_required"
                            checked={newField.is_required}
                            onChange={handleFieldChange}
                        />{' '}
                        Required
                    </label>
                    <button onClick={() => (editingField ? handleSaveEdit(activeTab) : handleAddField(activeTab))} className="button-save">
                        <FontAwesomeIcon icon={faSave} /> {editingField ? 'Save Changes' : 'Add Field'}
                    </button>
                </div>

                {renderFields()}

                <button onClick={onClose} className="button-cancel">
                    <FontAwesomeIcon icon={faTimes} /> Close
                </button>
            </div>
        </div>
    );
}

export default AssetManagementModal;
